import axios from "axios"

export const rechargeCheckout = async line_items => {
  const data = await axios.post("https://www.kissmyketo.com/.netlify/functions/recharge-subscription", line_items)
  return data
}

export const rechargeUpdateCheckout = async line_items => {
  const data = await axios.post(
    "https://www.kissmyketo.com/.netlify/functions/recharge-update-subscription",
    line_items
  )
  return data
}

export const postscriptSubscriberRegister = async payload => {
  return await axios.post("https://api.postscript.io/v1/subscribers", payload, {
    headers: {
      Authorization: "Bearer " + process.env.GATSBY_POSTSCRIPT_API_KEY,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
}
